<template>
  <section class="category-form">
    <b-overlay :show="loading" no-wrap spinner-variant="primary" ></b-overlay>
    <validation-observer ref="observer" class="w-100">
      <div class="w-100">
        <b-form-group :label="$t('TitleUz')">
          <validation-provider
              #default="{ errors }"
              :name="$t('TitleUz')"
              rules="required"
          >
            <b-input v-model="form.name_uz" required></b-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <div class="w-100">
        <b-form-group :label="$t('TitleRu')">
          <validation-provider
              #default="{ errors }"
              :name="$t('message.TitleRu')"
              rules="required"
          >
            <b-input v-model="form.name_ru" required></b-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <div class="w-100">
        <b-form-group :label="$t('TitleEn')">
          <validation-provider
              #default="{ errors }"
              :name="$t('TitleEn')"
              rules="required"
          >
            <b-input v-model="form.name_en" required></b-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <div class="w-100">
        <b-form-group :label="$t('RegisterDate')">
          <validation-provider
              #default="{ errors }"
              :name="$t('CategoryEn')"
              rules="required"
          >
            <input type="date" class="form-control" v-model="form.register_date">
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
    </validation-observer>
    <div class="w-100 mt-3 d-flex justify-content-end">
      <b-button variant="success" @click="saveSubmit" >{{$t('Add')}}</b-button>
    </div>
  </section>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { formTemplate } from '@/utils/mixins/formTemplate'
import { addCategories, editCategory, getCategory } from '@/api/documents'

function initForm(data) {
  const get = (key, value = null) => _.get(data, key, value)

  return {
    id: get('id', null),
    parent_id: get('parent_id', null),
    name_uz: get('name_uz', null),
    name_ru: get('name_ru', null),
    name_en: get('name_en', null),
    register_date: get('register_date', null),
  }
}

const actions = {
  add: addCategories,
  update: editCategory,
  show: getCategory,
}

export default {
  name: 'DocCategoryForm',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [formTemplate],
  data() {
    return {
      actions,
      form: initForm(),
      required,
    }
  },
  methods: {
    reformatData() {
      this.form.parent_id = this.$route.params.id
      const form = this.prepareFormData(this.form)
      return form
    },
    setForm(data) {
      this.form = initForm(data)
    },
  },
}
</script>

<style scoped>

</style>
