var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"category-form"},[_c('b-overlay',{attrs:{"show":_vm.loading,"no-wrap":"","spinner-variant":"primary"}}),_c('validation-observer',{ref:"observer",staticClass:"w-100"},[_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('TitleUz')}},[_c('validation-provider',{attrs:{"name":_vm.$t('TitleUz'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"required":""},model:{value:(_vm.form.name_uz),callback:function ($$v) {_vm.$set(_vm.form, "name_uz", $$v)},expression:"form.name_uz"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('TitleRu')}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.TitleRu'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"required":""},model:{value:(_vm.form.name_ru),callback:function ($$v) {_vm.$set(_vm.form, "name_ru", $$v)},expression:"form.name_ru"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('TitleEn')}},[_c('validation-provider',{attrs:{"name":_vm.$t('TitleEn'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"required":""},model:{value:(_vm.form.name_en),callback:function ($$v) {_vm.$set(_vm.form, "name_en", $$v)},expression:"form.name_en"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('RegisterDate')}},[_c('validation-provider',{attrs:{"name":_vm.$t('CategoryEn'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.register_date),expression:"form.register_date"}],staticClass:"form-control",attrs:{"type":"date"},domProps:{"value":(_vm.form.register_date)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "register_date", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('div',{staticClass:"w-100 mt-3 d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.saveSubmit}},[_vm._v(_vm._s(_vm.$t('Add')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }